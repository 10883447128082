import Vue from "vue";
import App from "./Web.vue";
import router from "./router";

import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import "./css/index.css";

import BaiduMap from "vue-baidu-map";

import Vant from 'vant';
import 'vant/lib/index.css';

Vue.use(Vant);

import "./utils/fixle";

Vue.config.productionTip = false;

Vue.use(ElementUI);

/* 路由发生变化修改页面title */
router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = `锶想（广州）科技有限公司`;
  }
  next();
});

Vue.use(BaiduMap, {
  // ak 是在百度地图开发者平台申请的密钥 详见 http://lbsyun.baidu.com/apiconsole/key */
  ak: "HVgiPYzEydwkpieghx6Z2DBfF7dZ0l6x",
});

new Vue({
  render: (h) => h(App),
  router,
}).$mount("#app");
